@import '~antd/dist/antd.css';
body {
  margin: 0;
  /* padding: 1rem; */
  font-family: sans-serif;
  background: #FAF9F3 0% 0% no-repeat;
}
.on-datepicker-error {
  border-color: red !important;
  color: red !important;
}